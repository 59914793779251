<template>
  <div class="form-view-content bg-content animation">
    <div class="form">
      <div class="skip">
        <el-button @click="skip()">
          <div>
            <el-icon>
              <CaretRight/>
            </el-icon>
            <el-icon class="icon-2">
              <CaretRight/>
            </el-icon>
          </div>
          skip
        </el-button>
      </div>
      <div class="head">
        <img :src="configVars?.logo_app_url" alt="logo_app">
        <h2 v-if="configVars?.form_title" v-html="configVars?.form_title"></h2>
        <p v-if="configVars?.form_subtitle" v-html="configVars?.form_subtitle"></p>
      </div>
      <div class="body">
        <form-component></form-component>
      </div>
      <div class="footer">
        <p v-html="configVars?.text_footer_form"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import FormComponent from '@/components/FormComponent.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CaretRight } from '@element-plus/icons-vue';
import IndexedDB from '../../utils/indexedDB';

export default {
  name: 'FormView',
  components: { FormComponent, CaretRight },
  data() {
    return {};
  },
  computed: {
    ...mapState(['configVars']),
  },
  methods: {
    ...mapMutations(['setStartTime']),
    skip() {
      IndexedDB.addAnimationTop();
      this.$store.commit('setStartTime', new Date());
      setTimeout(() => {
        this.$router.push('/trivia');
      }, 500);
    },
  },
};
</script>
<style lang="scss">
.form-view-content {
  .form {
    // .skip {
    //   .el-button {
    //     width: 98px;
    //     height: 42px;
    //     background: #FFFFFF;
    //     border-radius: 58px;
    //     font-family: "Futura Std Bold", sans-serif;
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 16px;
    //     line-height: 21px;
    //     text-align: right;
    //     text-transform: uppercase;
    //     color: #FA4801;
    //     position: absolute;
    //     right: 40px;
    //     top: 20px;

    //     & > span {
    //       gap: 10px;
    //     }

    //     .icon-2 {
    //       margin-left: -23px;
    //     }

    //     &:hover {
    //       background: rgba(255, 242, 237, 0.76);
    //     }
    //   }
    // }

    .body {
      margin: auto;

      .el-checkbox {
        .el-checkbox__inner {
          background: rgba(255, 245, 241, 0.65);
          border: 1.63125px solid #752809;
          width: 25px;
          height: 24px;

          &::after {
            height: 12px;
            left: 10px;
            top: 3px;
            border: 1px solid #752809;
            box-sizing: content-box;
            content: "";
            border-left: 0;
            border-top: 0;
            position: absolute;
            transition: transform 0.15s ease-in 50ms;
            transform-origin: center;
          }
        }
      }

    }

    .footer {
      bottom: 20px;

      * {
        text-align: center;
      }
    }
  }
}
</style>
