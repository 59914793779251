import { createMemoryHistory, createRouter } from 'vue-router';
import AgeView from '@/views/AgeView.vue';
import FormView from '@/views/FormView.vue';
import IntroView from '../views/IntroView.vue';
import TriviaView from '../views/TriviaView.vue';
import NetworkView from '../views/NetworkView.vue';
import WinnerView from '../views/WinnerView.vue';

const routes = [
  {
    path: '/age-gate',
    name: 'AgeView',
    component: AgeView,
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/form',
    name: 'Form Data',
    component: FormView,
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/:brandSlug?/:campaignSlug?',
    name: 'intro',
    component: IntroView,
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/trivia',
    name: 'trivia',
    component: TriviaView,
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/network',
    name: 'network',
    component: NetworkView,
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/winner',
    name: 'winner',
    component: WinnerView,
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  // {
  //   path: '/:catchAll(.*)',
  //   name: 'notfound',
  //   component: NotFoundView,
  // },
];
// const mode = process.env.VUE_APP_ROUTER_MODE ?? 'history';
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL) : createMemoryHistory(),
  history: createMemoryHistory(),
  base: '/',
  routes,
});

export default router;
