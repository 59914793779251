<template>
  <div class="age-view-content bg-content">
    <RestartButton
      @click="restartTrivia"
      backgroundColor="#FFFFFF"
      fontColor="#FA4801"
      :text="restartButtonText"
    />
    <div class="form">
      <div class="head">
        <img :src="configVars?.logo_app_url" alt="logo_app">
        <div v-html="configVars?.winner_view_paragraph_title"></div>
      </div>
      <div class="body">
        <QRCode />

        <p class="bold" v-html="configVars?.winner_view_paragraph_below_qr"></p>
        <p class="light" v-html="configVars?.text_footer"></p>
      </div>
    </div>
  </div>
</template>

<script>
import RestartButton from '@/components/RestartButton.vue';
import QRCode from '@/components/QRCode.vue';
import { mapState } from 'vuex';

export default {
  name: 'WinnerView',
  components: {
    RestartButton,
    QRCode,
  },
  data() {
    return {
      timer: null,
      timeout: 30,
      remaining: 30,
    };
  },
  computed: {
    ...mapState(['isBrowserReloaded', 'brandSlug', 'campaignSlug', 'configVars']),
    restartButtonText() {
      const { remaining, formatTime } = this;
      const remainingString = formatTime(remaining);

      if (this.configVars.restart_timer > 0) {
        return `Restart ${remainingString}`;
      }

      return 'Restart';
    },
  },
  methods: {
    restartTrivia() {
      this.$router.push(`/${this.brandSlug}/${this.campaignSlug}`);
    },
    formatTime(numSeconds) {
      const minutes = Math.floor(numSeconds / 60);
      const seconds = numSeconds % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      // if (minutes > 0) {
      //   return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      // }
      // return `${seconds}`;
    },
    startTimer() {
      if (this.timer == null) {
        this.resetTimer();
      }
      this.timer = setInterval(() => {
        this.remaining -= 1;
        if (this.remaining <= 0) {
          this.remaining = this.timeout;
          this.resetTimer();
          this.$router.push(`/${this.brandSlug}/${this.campaignSlug}`);
        }
      }, 1000);
    },
    resetTimer() {
      if (this.timer == null) {
        return;
      }
      clearInterval(this.timer);
      this.timer = null;
    },
  },
  mounted() {
    if (this.configVars.restart_timer > 0) {
      this.timeout = this.configVars.restart_timer;
      this.remaining = this.configVars.restart_timer;
      this.startTimer();
    }
  },
  beforeUnmount() {
    this.resetTimer();
  },
};
</script>

<style lang="scss">
.age-view-content {
  .form {
    .body {
      gap: 0;
    }
  }

  p.light {
    font-family: 'Futura Std Light' !important;
  }
  .el-message-box__title {
    padding-left: 10px;

    span {
      font-family: "Futura Std Book", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 48px;
      text-transform: uppercase;
    }
  }

  .el-message-box__message {
    text-align: center;

    p {
      font-family: "Futura Std Light", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-transform: uppercase;
    }
  }

  .el-message-box__btns {
    .el-button {
      font-family: "Futura Std Bold", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 37px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #FA4700;
      background: #FFFFFF;
      box-shadow: 0px 6.20541px 12.4108px rgba(183, 53, 2, 0.88);
      border-radius: 54px;
      border: 1px solid;
    }
  }

  h3{
    font-family: 'Futura Std Book';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    b{
      font-weight: bold;
    }
  }

  h2{
    margin-bottom: 0;
    padding: 0;
  }

  h3{
    margin: 0;
    padding: 0;
  }

  .body{
    p{
      max-width: 499px;
      font-family: 'Futura Std Book';
      font-style: normal;
      font-weight: 650;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      color: #FFFFFF;
    }
  }
}

</style>
