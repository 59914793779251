<!-- eslint-disable max-len -->
<template>
  <div class="intro-view__trivia-game">
    <img :src="this.configVars?.logo_2_brand" alt="" srcset="">

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['configVars']),
  },
};
</script>

<style lang="scss" scoped>
.intro-view__trivia-game {
}
</style>
