<template>
  <div :style="cssVars">

    <router-view/>
  </div>
  <!--  <button v-if="deferredPrompt" @click="installApp" @keypress="installApp">
      Install App!
    </button>-->
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Swal from 'sweetalert2';
import { setCss } from '../utils/general';
import IndexedDB from '../utils/indexedDB';
import Api from '../utils/api';

export default {
  name: 'App',
  data() {
    return {
      campaignInfo: [],
      deferredPrompt: null,
      onLine: false,
      questions: [],
      settings: {},
      showInstallPrompt: false,
      timer: null,
    };
  },
  computed: {
    ...mapState(['isBrowserReloaded', 'brandSlug', 'campaignSlug', 'configVars']),
    /**
     * Computes CSS variables from the settings object.
     *
     * @return {Object} An object containing CSS variables as key-value pairs.
     */
    // cssVars() {
    //   const { settings } = this;
    //   const variables = {};
    //   const configVar = {};
    //   if (settings) {
    //     // eslint-disable-next-line no-restricted-syntax
    //     for (const [key, value, type] of Object.entries(settings)) {
    //       // eslint-disable-next-line no-continue
    //       if (value == null) continue;
    //       debugger;
    //       if (type === 'css') {
    //         const cssKey = `--${key}`;
    //         variables[cssKey] = value;
    //       } else if (type === 'config') {
    //         configVar[key] = value;
    //       }
    //     }

    //     this.setConfigVars(configVar);
    //   }

    //   return variables;
    // },
    cssVars() {
      // eslint-disable-next-line no-unused-vars
      if (this.settings) {
        const {
          css,
          config,
          cssPlain,
          configImages,
        } = this.extractConfigAndCss(this.settings);

        const merged = { ...config, ...configImages };

        this.setConfigVars(merged);
        const vars = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(css)) {
          const cssKey = `--${key}`;
          vars[cssKey] = value;
        }

        if (cssPlain) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [, value] of Object.entries(cssPlain)) {
            setCss(value, 'trivia_styles');
          }
        }

        return vars;
      }

      return {};
    },
  },
  watch: {
    async onLine(val) {
      if (val) {
        console.info('🚀 ------------------------------------------🚀');
        console.info('💡 ~ file: App.vue:28 ~ you Are onLine ~ :', val);
        console.info('🚀 ------------------------------------------🚀');

        if (!this.brandSlug) {
          Swal.fire({
            title: 'Error!',
            text: 'Please configure brand',
            icon: 'error',
            confirmButtonText: 'Okay',
          });
          return;
        }

        if (!this.campaignSlug) {
          Swal.fire({
            title: 'Error!',
            text: 'Please configure campaign',
            icon: 'error',
            confirmButtonText: 'Okay',
          });
          return;
        }

        if (this.brandSlug && this.campaignSlug) {
          this.campaignInfo = await this.getCampaignInfo();
          this.questions = this.campaignInfo.questions;
          this.settings = this.campaignInfo.settings;

          await IndexedDB.insertIndexedDB({
            name: `${this.brandSlug}-${this.campaignSlug}`,
            data: this.campaignInfo,
          });

          await IndexedDB.syncInsertEntries({
            brandSlug: this.brandSlug,
            campaignSlug: this.campaignSlug,
          });
        }

        await this.startTimer();
      } else {
        this.campaignInfo = await IndexedDB.getIndexedDB(`${this.brandSlug}-${this.campaignSlug}`);
        const { questions, settings } = this.campaignInfo;
        this.questions = questions;
        this.settings = settings;
        console.warn('🚀 ------------------------------------------🚀');
        console.warn('💀 ~ file: App.vue:28 ~ you Are offLine ~ :', val);
        console.warn('🚀 ------------------------------------------🚀');
      }
    },
  },
  methods: {
    ...mapMutations(['setIsBrowserReloaded', 'setBrandSlug', 'setCampaignSlug', 'setConfigVars']),
    extractConfigAndCss(data) {
      // Extract the `css` and `config` properties from the data object
      const css = data.css ? { ...data.css } : {};
      const config = data.config ? { ...data.config } : {};
      const cssPlain = data.css_plain ? { ...data.css_plain } : {};
      const configImages = data.images ? { ...data.images } : {};

      // Return the variables in an object or you can directly use them outside this function
      return {
        css,
        config,
        cssPlain,
        configImages,
      };
    },
    async startTimer() {
      if (this.timer == null) {
        this.resetTimer();
      }
      this.timer = setInterval(async () => {
        await IndexedDB.syncInsertEntries({
          brandSlug: this.brandSlug,
          campaignSlug: this.campaignSlug,
        });
      }, 30000);
    },
    resetTimer() {
      if (this.timer == null) {
        return;
      }
      clearInterval(this.timer);
      this.timer = null;
    },
    installApp() {
      console.log('install requested');
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.deferredPrompt = null;
          this.showInstallPrompt = false;
        });
      }
    },
    updateOnlineStatus(e) {
      const { type } = e;
      this.onLine = type === 'online';
    },
    async getCampaignInfo() {
      try {
        const { data } = await Api.get(`/general/${this.brandSlug}/${this.campaignSlug}`);
        const [campaignInfo] = data.rows;
        return campaignInfo;
      } catch (error) {
        console.log('🚀 ---------------------------------------------------🚀');
        console.log('🚀 ~ file: App.vue:42 ~ getGeneral ~ error:', error);
        console.log('🚀 ---------------------------------------------------🚀');
        return [];
      }
    },
    setParams() {
      const params = window.location.pathname.split('/').filter((part) => part !== '');

      const brandSlug = params[0];
      const campaignSlug = params[1];

      this.setBrandSlug(brandSlug);
      this.setCampaignSlug(campaignSlug);
    },
  },
  async created() {
    this.setParams();
    window.addEventListener('beforeinstallprompt', (event) => {
      // console.log('🚀 ~ file: App.vue:44 ~ window.addEventListener ~ event:', event);
      event.preventDefault();
      this.deferredPrompt = event;
      this.showInstallPrompt = true;
    });

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    const loadImages = [
      { src: '/js/app.js' },
      { src: '/css/app.css' },
      { src: '/js/runtime.js' },
      { src: '/img/bg_app.png' },
      { src: '/img/arrow-rotate-left-orange.svg' },
      { src: '/img/qrcode.svg' },
      { src: '/img/check.svg' },
      { src: '/img/x.svg' },
      { src: '/img/arrow-rotate-left.svg' },
      { src: '/img/decoration.svg' },
      { src: '/img/check-A.svg' },
      { src: '/img/Wrong-A.svg' },
      { src: '/img/forma.svg' },
    ];
    const campaignInfo = await IndexedDB.getIndexedDB(`${this.brandSlug}-${this.campaignSlug}`);
    const { questions, settings } = campaignInfo;

    if (questions?.length > 0) {
      for (let i = 0; i < questions.length; i += 1) {
        const question = questions[i];
        for (let j = 0; j < question.answers.length; j += 1) {
          const answer = question.answers[j];
          loadImages.push({ src: `${process.env.VUE_APP_IMAGES_BASE_URL}/${answer.attachment}` });
        }
      }
    } else {
      console.log('No questions founded');
    }

    // Loop through the values of the settings object
    const imagesValues = Object.values(settings?.images || {});
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < imagesValues.length; i++) {
      loadImages.push({ src: imagesValues[i] });
    }

    if (navigator.onLine) {
      IndexedDB.preloadImages(loadImages);
    }
  },
  async mounted() {
    this.onLine = navigator.onLine;

    if (!navigator.onLine) {
      this.campaignInfo = await IndexedDB.getIndexedDB(`${this.brandSlug}-${this.campaignSlug}`);
      const { questions, settings } = this.campaignInfo;
      this.questions = questions;
      this.settings = settings;
    }
    // if (this.brandSlug && this.campaignSlug) {
    //   const { data } = await Api.get(`/general/${this.brandSlug}/${this.campaignSlug}`);
    //   const [campaignInfo] = data.rows;
    //   console.log(campaignInfo);
    //   this.campaignInfo = campaignInfo;
    // }
    console.log(await IndexedDB.getIndexedDB('APEROLDB'), 'APEROLDB');
    console.log(await IndexedDB.getIndexedDB('APEROLBACK'), 'APEROLBACK');
  },
  beforeUnmount() {
    this.resetTimer();
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
};
</script>

<style lang="scss">
@import 'styles/style.scss';

</style>
