<script>
import { mapState } from 'vuex';

export default {
  name: 'RestartButton',
  props: {
    backgroundColor: {
      type: String,
      default: '#FA4801',
    },
    fontColor: {
      type: String,
      default: '#FFFFFF',
    },
    text: {
      type: String,
      default: 'Restart',
    },
  },
  data: () => ({}),
  computed: {
    ...mapState(['configVars']),
  },
  methods: {},
  mounted() {},
};
</script>
<template>
  <button @click="$emit('restart-trivia')" class="button_restart">
    <img :src="configVars?.reload_icon" alt="reload">

    <span>{{ text }}</span>
  </button>
</template>

<style lang="scss" scoped>
//  .button_restart {
//     background: none;
//     color: inherit;
//     border: none;
//     padding: 0;
//     font: inherit;
//     cursor: pointer;
//     outline: inherit;

//     position: absolute;
//     min-width: 159px;
//     width: 129px;
//     height: 42px;
//     right: 55px;
//     top: 25px;

//     background: #FFFFFF;
//     border-radius: 58px;
//     color: var(--background-color-button-restart);
//     font-size: 16px;
//     font-family: 'Futura Std Bold';
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 7px;

//     :hover{
//       opacity: 0.8;
//     }
//   }
</style>
