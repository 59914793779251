<!-- eslint-disable max-len -->
<template>
  <div class="intro-view__aperol-spritz">
    <img :src="this.configVars?.logo_1_brand" alt="" srcset="">
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['configVars']),
  },
};

</script>

<style lang="scss" scoped>
.intro-view__aperol-spritz {
  background-image: var(--test);
}
</style>
