import { createStore } from 'vuex';
// eslint-disable-next-line import/no-extraneous-dependencies
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  state() {
    return {
      uuid: null,
      startTime: null,
      isBrowserReloaded: false,
      brandSlug: null,
      campaignSlug: null,
      configVars: null,
    };
  },
  getters: {
    getUUID(state) {
      return !!state.uuid;
    },
    getStartTime(state) {
      return !!state.startTime;
    },
    getIsBrowserReloaded(state) {
      return !!state.isBrowserReloaded;
    },
    getBrandSlug(state) {
      return !!state.brandSlug;
    },
    getCampaignSlug(state) {
      return !!state.campaignSlug;
    },
  },
  mutations: {
    setUUID(state, uuid) {
      state.uuid = uuid;
    },
    setStartTime(state, startTime) {
      state.startTime = startTime;
    },
    setIsBrowserReloaded(state, isBrowserReloaded) {
      state.isBrowserReloaded = isBrowserReloaded;
    },
    setBrandSlug(state, brandSlug) {
      state.brandSlug = brandSlug?.toLowerCase();
    },
    setCampaignSlug(state, campaignSlug) {
      state.campaignSlug = campaignSlug;
    },
    setConfigVars(state, configVars) {
      state.configVars = configVars;
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});
