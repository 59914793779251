<!-- eslint-disable max-len -->
<template>
  <div class="qr-container">
    <qrcode-vue
      :value="configVars.url_qr_code"
      :size="250"
      render-as="svg"
      foreground="var(--primary-color)"
      background="transparent"
      level="H"
      class="qr-image"
      :margin="0"
    ></qrcode-vue>
    <img :src="configVars.center_image_qr_code" alt="">
  </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue';

import { mapState } from 'vuex';

export default {
  components: {
    QrcodeVue,
  },

  computed: {
    ...mapState(['configVars']),
  },
};
</script>
<style lang="scss">
.qr-container {
  margin-top: 40px;
  width: 250px;
  height: 250px;
  position: relative;
  background-color: #fff;
  padding: 25px;

  img {
    position: absolute;
    inset: 0;
    margin: auto;
  }
}
</style>
