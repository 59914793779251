<template>
  <div class="form-component-content">
    <el-form ref="formData"
             :rules="rules"
             :model="entry"
             label-width="120px"
             label-position="top">
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form-item label="First Name" prop="firstName">

            <el-input type="text"
                      v-model="entry.firstName">

            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Last Name  (Optional)" prop="lastName">
            <el-input type="text"
                      v-model="entry.lastName">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form-item label="Email" prop="email">
            <el-input type="email"
                      v-model="entry.email">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="ZIP (Optional)" prop="zipCode">
            <el-input type="tel"
                      inputmode="numeric"
                      @input="handleChangeZipCode()"
                      v-model="entry.zipCode">
            </el-input>

          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item prop="optIn">
        <el-checkbox v-model="entry.optIn"
                     label="By checking this box, you are opting in to receive
        future marketing and promotional communications from Aperol and other
        Campari Group Brands.*" size="large"/>
      </el-form-item>
      <el-form-item>
        <div class="button">
          <el-button type="primary" class="submit_button" @click="onSubmit()" plain
          v-html="configVars?.text_submit_button"
          ></el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import IndexedDB from '../../utils/indexedDB';

export default {
  name: 'FormComponent',
  data() {
    return {
      entry: {
        firstName: '',
        lastName: '',
        email: '',
        optIn: false,
        zipCode: '',
      },
      rules: {
        firstName: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
            type: 'string',
          },
          { min: 2, message: 'Minimum 2 characters', trigger: 'blur' },
        ],
        email: [
          {
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          },
          { type: 'email', message: 'This field is invalid', trigger: 'blur' },
        ],
        zipCode: [
          {
            validator: this.validateZipCode,
            message: 'Invalid ZIP code',
            trigger: 'blur',
            type: 'tel',
          },
        ],
        optIn: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(''));
              } else {
                callback();
              }
            },
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(['uuid', 'configVars']),
  },
  watch: {},
  methods: {
    ...mapMutations(['setStartTime']),
    extractUSZipCode(str) {
      const zipCodeRegex = /\b\d{5}(?:-\d{4})?\b/g; // regular expression for US zip codes

      const matches = str.match(zipCodeRegex); // get all matches of zip codes in the string

      if (matches) {
        // return the first match (assuming only one zip code in the string)
        return matches[0];
      }
      // no zip codes found in the string
      return null;
    },
    handleChangeZipCode() {
      const isNumOrHyphen = (str) => /^[0-9-]+$/.test(str);
      const value = this.entry.zipCode;
      if (!isNumOrHyphen(value)) {
        this.entry.zipCode = '';
        return;
      }
      let response = value.replaceAll('-', '');
      if (value.length > 5) {
        response = `${response.substring(0, 5)}-${response.substring(5)}`;
      }

      this.entry.zipCode = response;
    },
    async onSubmit() {
      const isValid = await this.validateForm('formData');
      if (!isValid) {
        this.$alert('Please complete your name, email and opt-in to submit this form.', 'Complete the form', {
          confirmButtonText: 'OK',
        });
        console.error('Validate Form Fields');
        return;
      }
      const indexed = process.env.VUE_APP_INDEXED_DB;
      await IndexedDB.updateEntries({ name: indexed, user: this.uuid, data: this.entry });
      this.$store.commit('setStartTime', new Date());
      IndexedDB.addAnimationTop();
      setTimeout(() => {
        this.$router.push('/trivia');
      }, 500);
    },
    validateForm(ref) {
      return new Promise((resolve) => {
        this.$refs[ref].validate((valid) => {
          if (valid) {
            resolve(true);
          }
          resolve(false);
        });
      });
    },
    validateZipCode(rule, value, callback) {
      const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
      if (value) {
        if (zipCodeRegex.test(value)) {
          callback();
        } else {
          callback(new Error('Invalid ZIP code'));
        }
      } else {
        callback();
      }
    },
  },
  mounted() {
  },
};

</script>

<style lang="scss">
.form-component-content {
  width: 80%;
  @media (orientation: portrait) {
    width: 85%;
  }

  .el-form {
    .el-row {
      margin-bottom: 20px;
      @media (orientation: portrait) {
        flex-direction: column;
        .el-col-12 {
          max-width: 100%;
        }
      }
    }

    .el-form-item__label {
      font-family: 'Futura Std Medium Condensed', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-align: left;
      text-transform: uppercase;
      color: #FDFBFB;
    }

    .el-input {
      .el-input__wrapper {
        border-radius: 0 !important;
        color: #752809;
        background: rgba(255, 242, 237, 0.46);
        border: 2.15926px solid #FA4801;

        &.is-focus {
          background: rgba(255, 242, 237, 0.76);
          border: 2.15926px solid #FFFFFF;
        }

        .el-input__inner {
          color: #752809;
          width: 390.23px;
          height: 52.03px;
          box-sizing: border-box;
          font-family: 'Futura Std Medium Condensed', sans-serif;;
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
          text-align: left;
          outline: none !important;

          &::placeholder {
            color: #752809;
          }
        }

        &.is-focus {
          background: rgba(255, 242, 237, 0.76);
          border: 2.15926px solid #FFFFFF;
        }
      }
    }

    .el-checkbox {
      .el-checkbox__label {
        font-family: 'Futura Std Book', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #FFFFFF;
        white-space: initial;
        text-align: left;
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .el-form-item__error {
      color: #ffff;
      font-size: 16px;
      padding-top: 6px;
    }

    .el-form-item.is-required:not(.is-no-asterisk).asterisk-left > .el-form-item__label:before {
      color: #ffffff;
    }
  }
}
</style>
